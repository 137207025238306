<template>
  <div>
    <div
      class="modal fade"
      id="modalTTTQM"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTTTQMLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document" v-show="curr_disease">
        <div class="modal-content p-3">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5 class="modal-title" id="modalTTTQMLabel">Thông qua mổ</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="fs-18 fw-500">&times;</span>
            </button>
          </div>

          <div>
            <div class="m-0 mb-4 row">
              <div class="col-6">
                <p class="robo-14-400">
                  Họ tên người bệnh:
                  <span class="fw-700">{{ form.name }}</span>
                </p>
                <p class="robo-14-400">Địa chỉ: {{ form.address }}</p>
                <div class="d-flex mb-2">
                  <p class="robo-14-400">Chẩn đoán: {{ form && form.diagnostic }}</p>
                </div>
              </div>
              <div class="col-4">
                <p class="robo-14-400">
                  Tuổi:
                  <span class="fw-700">{{ form.age }}</span>
                </p>
                <div class="d-flex align-items-center">
                  <p class="robo-14-400 mb-0 mr-2">Khoa:</p>
                  <input
                    type="text"
                    class="border fs-14 form-control bg-white px-2 py-1"
                    v-model="form.dept"
                  />
                </div>
              </div>
              <div class="col-2">
                <p class="robo-14-400">
                  Nam/Nữ:
                  <span class="fw-700">{{ getGender(form.sex) }}</span>
                </p>
              </div>
            </div>
            <!-- <div class="d-flex align-items-center mb-2">
      <p class="robo-14-400 mb-0 mr-2">Hội chẩn lúc:</p>
      <input type="datetime-local" class="border" v-model="form.mdt_time" />
            </div>-->
            <div class="m-0 mb-4">
              <div>
                <label
                  for="txt-name"
                  class="robo-16-500 fw-bold"
                >I. Tóm tắt bệnh án và tình trạng bệnh nhân trước mổ</label>
                <EditorInput
                  id="medical_history-tqmf"
                  :prop_text="form.medical_history"
                  @onChangeContent="(content) => form.medical_history = content"
                />
              </div>
            </div>
            <div class="m-0 mb-4">
              <div>
                <label
                  for="txt-name"
                  class="robo-16-500 fw-bold"
                >Kết luận (sau khi đã khám lại và thảo luận)</label>
                <EditorInput
                  id="conclusion-tqmf"
                  :prop_text="form.conclusion"
                  @onChangeContent="(content) => form.conclusion = content"
                />
              </div>
            </div>
            <div class="robo-16-500">
              <p>II. Xét nghiệm</p>
            </div>
            <div class="robo-16-500">
              <p>1. XN máu</p>
            </div>
            <div class="m-0 mb-3 row">
              <div class="col-6">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">BC</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_bc"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Ure</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_ure"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Bilirubin TP</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_bilirubin_tp"
                  />
                </div>
              </div>
            </div>
            <div class="m-0 mb-3 row">
              <div class="col-6">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">HST</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_hst"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Creatinin</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_creatinin"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Bilirubin TP</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_bilirubin_tt"
                  />
                </div>
              </div>
            </div>
            <div class="m-0 mb-3 row">
              <div class="col-6">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">HC</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_hc"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Glucose</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_glucose"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">GOT</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_got"
                  />
                </div>
              </div>
            </div>
            <div class="m-0 mb-3 row">
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">HCT</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_hct"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Nhóm Máu:</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_blood"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Protit</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_protit"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">GPT</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_gpt"
                  />
                </div>
              </div>
            </div>
            <div class="m-0 mb-3 row">
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">TC</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_tc"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">PT%</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_pt"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="txt-name" class="robo-14-500 fw-bold">Cholesterol</label>
                  <input
                    type="text"
                    class="form-control mt-1"
                    id="txt-name"
                    placeholder
                    v-model="form.test_cholesterol"
                  />
                </div>
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">2. Nước tiểu</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.urine"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">3. Xquang Ngực</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.xray"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">4. Điện tim</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.electrocar"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">5. Siêu âm</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.ultrasound"
                />
              </div>
            </div>
            <div class="m-0 mb-4">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">6. Nội soi</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.endoscopy"
                />
              </div>
            </div>
            <div class="robo-16-500">
              <p>III. Chuẩn bị bệnh nhân, kíp mổ và phương pháp mổ</p>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">1. Thử phản ứng thuốc</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.drug_reaction"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">2. Kháng sinh trước mổ</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.antibiotics"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">3. Phương pháp phẫu thuật</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.surgery_method"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">4. Phương pháp vô cảm</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.anaesthetic_method"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">5. Kíp mổ</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.surgical_team"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">6. Kíp gây mê</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.anaesthetic_team"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">7. Giải phẫu bệnh</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.anatomic"
                />
              </div>
            </div>
            <div class="m-0 mb-3 row">
              <div class="form-group col-6">
                <label for="txt-name" class="robo-14-500 fw-bold">8. Dự trù máu</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.blood_plan"
                />
              </div>
              <div class="form-group col-6">
                <label for="txt-name" class="robo-14-500 fw-bold">Dự kiến ngày mổ</label>
                <input
                  type="date"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.surgery_date"
                />
              </div>
            </div>
            <div class="m-0 mb-4">
              <div class="form-group">
                <label for="txt-name" class="robo-14-500 fw-bold">10. Dự kiến khó khăn tiên lượng</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.prognostic"
                />
              </div>
            </div>
            <div class="robo-16-500">
              <p>Người kí biên bản</p>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-16-500 fw-bold">Trưởng khoa PT và GMHS</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.gm_sur_anes_name"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-16-500 fw-bold">Trưởng Khoa Ngoại</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.gm_surgery_name"
                />
              </div>
            </div>
            <div class="m-0 mb-3">
              <div class="form-group">
                <label for="txt-name" class="robo-16-500 fw-bold">Bác sĩ điều trị</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  id="txt-name"
                  placeholder
                  v-model="form.doctor_name"
                />
              </div>
            </div>
            <div class="my-3 text-right">
              <button
                class="btn bg-pri bd-pri text-white px-5"
                @click="submit()"
                :disabled="loading"
              >Lưu</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TTTQMTemplate
      :form="form"
      :room_info="room_info"
      :save_pdf="save_pdf"
      @onSubmiting="onSubmiting"
    />
  </div>
</template>

<script>
import TTTQMTemplate from '../../templates/HoiChan/TQMHCHTTemplate.vue'
import mdtUtils from '../../utils/mdtUtils'
import EditorInput from '@/components/EditorInput.vue'

import appUtils from '../../utils/appUtils'
import moment from 'moment'
export default {
  name: 'TQMForm',
  components: { TTTQMTemplate, EditorInput },
  props: ['curr_disease', 'room_info'],
  data () {
    return {
      form: {
        name: '',
        age: '',
        sex: '',
        address: '',
        code: '',
        dept: '',
        diagnostic: '',
        mdt_time: '',
        treatment_progress: '',
        treatment_time: '',
        medical_history: '',
        conclusion: '',
        test_bc: '',
        test_ure: '',
        test_bilirubin_tp: '',
        test_bilirubin_tt: '',
        test_hst: '',
        test_creatinin: '',
        test_hc: '',
        test_glucose: '',
        test_got: '',
        test_hct: '',
        test_blood: '',
        test_protit: '',
        test_gpt: '',
        test_tc: '',
        test_pt: '',
        test_cholesterol: '',
        urine: '',
        xray: '',
        electrocar: '',
        ultrasound: '',
        endoscopy: '',
        drug_reaction: '',
        antibiotics: '',
        surgery_desc: '',
        surgery_method: '',
        method: '',
        surgical_team: '',
        blood_plan: '',
        surgery_date: moment().format('YYYY-MM-DD'),
        prognostic: '',
        gm_sur_anes_name: '',
        gm_surgery_name: '',
        doctor_name: '',
        anaesthetic_method: '',
        anatomic: '',
        anaesthetic_team: ''
      },
      save_pdf: 0,
      doctor_id: null,
      loading: false
    }
  },
  mounted () {
    this.doctor_id = appUtils.getLocalUser()?.doctor?.id
    this.processDisease(this.curr_disease)
  },
  watch: {
    curr_disease (d) {
      this.processDisease(d)
      if (d.clinic && !this.form.dept) {
        this.form.dept = d.clinic.name
      }
    }
  },
  methods: {
    processDisease (d) {
      if (d) {
        const operation = d.operation || d.mdt_room_disease?.operation
        if (operation) {
          this.form = {
            name: operation.name,
            age: operation.age,
            sex: operation.sex,
            address: operation.address,
            code: operation.code,
            dept: operation.dept,
            diagnostic: operation.diagnostic,
            treatment_progress: operation.treatment_progress,
            treatment_time: operation.treatment_time,
            // mdt_time: d.operation.mdt_time ? moment(d.operation.mdt_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm') : moment(this.room_info.start_time, 'HH:mm:ss').format('YYYY-MM-DDTHH:mm'),
            medical_history: operation.medical_history || '',
            conclusion: operation.conclusion || '',
            test_bc: operation.test_bc,
            test_ure: operation.test_ure,
            test_bilirubin_tp: operation.test_bilirubin_tp,
            test_bilirubin_tt: operation.test_bilirubin_tt,
            test_hst: operation.test_hst,
            test_creatinin: operation.test_creatinin,
            test_hc: operation.test_hc,
            test_glucose: operation.test_glucose,
            test_got: operation.test_got,
            test_hct: operation.test_hct,
            test_blood: operation.test_blood,
            test_protit: operation.test_protit,
            test_gpt: operation.test_gpt,
            test_tc: operation.test_tc,
            test_pt: operation.test_pt,
            test_cholesterol: operation.test_cholesterol,
            urine: operation.urine,
            xray: operation.xray,
            electrocar: operation.electrocar,
            ultrasound: operation.ultrasound,
            endoscopy: operation.endoscopy,
            drug_reaction: operation.drug_reaction,
            antibiotics: operation.antibiotics,
            surgery_desc: operation.surgery_desc,
            method: operation.method,
            surgical_team: operation.surgical_team,
            blood_plan: operation.blood_plan,
            surgery_date:
              operation.surgery_date || moment().format('YYYY-MM-DD'),
            prognostic: operation.prognostic,
            gm_sur_anes_name: operation.gm_sur_anes_name,
            gm_surgery_name: operation.gm_surgery_name,
            surgery_method: operation.surgery_method,
            doctor_name: operation.doctor_name,
            anaesthetic_method: operation.anaesthetic_method,
            anaesthetic_team: operation.anaesthetic_team,
            anatomic: operation.anatomic
          }
        } else {
          this.form = {
            name: d.patient_name,
            age: this.getAges(d.patient_birthday),
            sex: d.patient_gender,
            address: d.patient_address,
            code: d.code,
            diagnostic: d.patient_diagnostic,
            medical_history: d.patient_diseases_current_cure,
            conclusion: d?.conclusion || '',
            // mdt_time: d.start_time ? moment(d.start_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm'),
            dept: this.form.dept || '',
            treatment_progress: '',
            treatment_time: '',
            test_bc: '',
            test_ure: '',
            test_bilirubin_tp: '',
            test_bilirubin_tt: '',
            test_hst: '',
            test_creatinin: '',
            test_hc: '',
            test_glucose: '',
            test_got: '',
            test_hct: '',
            test_blood: '',
            test_protit: '',
            test_gpt: '',
            test_tc: '',
            test_pt: '',
            test_cholesterol: '',
            urine: '',
            xray: '',
            electrocar: '',
            ultrasound: '',
            endoscopy: '',
            drug_reaction: '',
            antibiotics: '',
            surgery_desc: '',
            method: '',
            surgical_team: '',
            blood_plan: '',
            surgery_method: '',
            surgery_date: moment().format('YYYY-MM-DD'),
            prognostic: '',
            gm_sur_anes_name: '',
            gm_surgery_name: '',
            doctor_name: d.doctor?.name,
            anaesthetic_method: '',
            anaesthetic_team: '',
            anatomic: ''
          }
        }
      }
    },
    submit () {
      this.loading = true
      this.save_pdf++
    },
    onSubmiting (file) {
      if (!this.curr_disease) return
      if (this.curr_disease.operation) {
        this.updateMdtDiseaseOperation(file)
      } else {
        this.createMdtDiseaseOperation(file)
      }
    },
    async createMdtDiseaseOperation (file = null) {
      let self = this
      // if (self.doctor_id !== self.curr_disease.doctor_id) return
      let params = { ...this.form }
      params.public_mdt_case_id = self.curr_disease.id
      // params.mdt_time = moment(params.mdt_time).format('HH:mm DD-MM-YYYY')
      params.surgery_date = moment(params.surgery_date).format('DD-MM-YYYY')
      if (file) params.file = file
      let formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .createPublicMdtCaseOperation(formData)
        .then(r => {
          self.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })
          self.$emit('refresh')
        })
        .finally(() => {
          self.loading = false
        })
    },
    async updateMdtDiseaseOperation (file = null) {
      let self = this
      // if (self.doctor_id !== self.curr_disease.doctor_id || !self.curr_disease.operation) return
      let params = { ...this.form }
      params.public_mdt_case_id = self.curr_disease.id
      // params.mdt_time = moment(params.mdt_time).format('HH:mm DD-MM-YYYY')
      params.surgery_date = moment(params.surgery_date).format('DD-MM-YYYY')
      if (file) params.file = file
      let formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .updatePublicMdtCaseOperation(self.curr_disease.operation.id, formData)
        .then(() => {
          self.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })
          self.$emit('refresh')
        })
        .finally(() => {
          self.loading = false
        })
    },
    getAges (date) {
      let bd = moment(date, 'YYYY-MM-DD')
      let age = moment().diff(bd, 'years')
      return age
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    openModal (show) {
      if (show) {
        this.$emit('reload')
      }

      window.$('#modalTTTQM').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
.robo-18-500 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.robo-14-400 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.robo-14-500 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.robo-16-500 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.fs-20 {
  font-size: 20px;
}

.form-control {
  height: unset;
  border-radius: 0 !important;
}
</style>
