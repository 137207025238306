<template>
  <div>
    <div class="d-flex align-items-center m-3 border-bottom pb-2" v-if="mdt_case">
      <a class="fs-18 txt-grey-600" @click.prevent="backToMdtDetail">
        {{ mdt_room ? mdt_room.name : "" }}
        <span
          class="spinner-border"
          role="status"
          v-if="!mdt_room"
        >
          <span class="sr-only">Loading...</span>
        </span>
      </a>
      <div v-if="mdt_case && mdt_case.public_mdt_session_id && mdt_case.mdt_date">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-2"
        >
          <path
            d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
            fill="#667085"
          />
        </svg>
        <a
          class="fs-18 txt-grey-600"
          @click.prevent="backToMdtSession"
        >Ngày {{ mdt_case.mdt_date && formatDate(mdt_case.mdt_date) }}</a>
      </div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mx-2"
      >
        <path
          d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
          fill="#667085"
        />
      </svg>
      <a href="javascript:;" class="fs-18 txt-grey-900 mb-0 disabled">Thông tin ca bệnh</a>
    </div>
    <div class="container radius-10 bg-white p-3">
      <div class="px-3" v-if="mdt_case">
        <div
          class="mb-3 d-flex justify-content-between"
          :set="(sttClass = mdtUtils.getStatusPublicMdtCase(mdt_case.status))"
        >
          <div>
            <p class="fs-18 txt-grey-600 mb-2">
              Trạng thái
              <span
                class="fs-16 fw-500 text-right mb-1"
                :class="sttClass.class"
              >{{ sttClass.text }}</span>
            </p>
          </div>
          <div>
            <button
              class="btn bg-pri bd-pri text-white px-3 mr-3"
              v-if="mdt_case && isMaster"
              @click="onUpdate"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.49902 14.3751V17.5001H5.62402L14.8407 8.28346L11.7157 5.15846L2.49902 14.3751ZM17.2574 5.8668C17.5824 5.5418 17.5824 5.0168 17.2574 4.6918L15.3074 2.7418C14.9824 2.4168 14.4574 2.4168 14.1324 2.7418L12.6074 4.2668L15.7324 7.3918L17.2574 5.8668Z"
                  fill="white"
                />
              </svg>
              Chỉnh sửa
            </button>
            <button
              class="btn bg-pri text-white fs-16"
              type="button"
              @click="openOriginCase"
            >Xem ca bệnh gốc</button>
          </div>
        </div>
        <!-- kết luận -->
        <div class="mb-3 row">
          <div class="col-md-6 mb-3">
            <div class="bg--E7EAF5 p-3 radius-10 d-flex">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 5H15C12.25 5 10.025 7.25 10.025 10L10 50C10 52.75 12.225 55 14.975 55H45C47.75 55 50 52.75 50 50V20L35 5ZM40 45H20V40H40V45ZM40 35H20V30H40V35ZM32.5 22.5V8.75L46.25 22.5H32.5Z"
                  fill="#20409B"
                />
              </svg>

              <div class="w-100 pl-3">
                <p class="fs-24 fw-500 mb-1 txt-grey-900">Biên bản hội chẩn</p>
                <p class="fs-14 txt-grey-800">
                  <span v-if="mdt_case.concluding">
                    Cập nhật lần cuối:
                    {{ formatHmDMY(mdt_case.concluding.updated_at) }}
                  </span>
                  <span v-if="!mdt_case.concluding">&nbsp;</span>
                </p>
                <div class="row">
                  <div v-if="mdt_case.concluding" :class="isMaster ? 'col-6' : 'col-12'">
                    <button
                      class="btn bg-inherit bd-pri border txt-pri w-100"
                      @click="openUrl(mdt_case.concluding.pdf)"
                    >
                      <span>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 6.5C16.54 6.5 19.92 8.63 21.57 12C19.92 15.37 16.54 17.5 12.75 17.5C8.96 17.5 5.58 15.37 3.93 12C5.58 8.63 8.96 6.5 12.75 6.5ZM12.75 4.5C7.75 4.5 3.48 7.61 1.75 12C3.48 16.39 7.75 19.5 12.75 19.5C17.75 19.5 22.02 16.39 23.75 12C22.02 7.61 17.75 4.5 12.75 4.5ZM12.75 9.5C14.13 9.5 15.25 10.62 15.25 12C15.25 13.38 14.13 14.5 12.75 14.5C11.37 14.5 10.25 13.38 10.25 12C10.25 10.62 11.37 9.5 12.75 9.5ZM12.75 7.5C10.27 7.5 8.25 9.52 8.25 12C8.25 14.48 10.27 16.5 12.75 16.5C15.23 16.5 17.25 14.48 17.25 12C17.25 9.52 15.23 7.5 12.75 7.5Z"
                            fill="#20419B"
                          />
                        </svg>
                      </span>
                      Xem
                    </button>
                  </div>
                  <div v-if="isMaster" :class="mdt_case.concluding ? 'col-6' : 'col-12'">
                    <button
                      class="btn bg-pri bd-pri text-white w-100"
                      @click="showDiseaseConcluding(true)"
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.99902 17.2505V21.0005H6.74902L17.809 9.94055L14.059 6.19055L2.99902 17.2505Z"
                            fill="white"
                          />
                          <path
                            d="M20.709 5.63055L18.369 3.29055C17.979 2.90055 17.349 2.90055 16.959 3.29055L15.129 5.12055L18.879 8.87055L20.709 7.04055C21.099 6.65055 21.099 6.02055 20.709 5.63055Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      {{ mdt_case.concluding ? "Chỉnh sửa" : "Soạn" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3" v-if="mdt_case.type">
            <div class="bg--E7EAF5 p-3 radius-10 d-flex">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 5H15C12.25 5 10.025 7.25 10.025 10L10 50C10 52.75 12.225 55 14.975 55H45C47.75 55 50 52.75 50 50V20L35 5ZM40 45H20V40H40V45ZM40 35H20V30H40V35ZM32.5 22.5V8.75L46.25 22.5H32.5Z"
                  fill="#20409B"
                />
              </svg>

              <div class="w-100 pl-3">
                <p class="fs-24 fw-500 mb-1 txt-grey-900">Thông qua mổ</p>

                <p class="fs-14 txt-grey-800">
                  <span v-if="mdt_case.operation">
                    Cập nhật lần cuối:
                    {{ formatHmDMY(mdt_case.operation.updated_at) }}
                  </span>
                  <span v-if="!mdt_case.operation">&nbsp;</span>
                </p>
                <div class="row">
                  <div v-if="mdt_case.operation" :class="isMaster ? 'col-6' : 'col-12'">
                    <button class="btn bg-inherit bd-pri border txt-pri w-100" @click="viewTTTQM()">
                      <span>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 6.5C16.54 6.5 19.92 8.63 21.57 12C19.92 15.37 16.54 17.5 12.75 17.5C8.96 17.5 5.58 15.37 3.93 12C5.58 8.63 8.96 6.5 12.75 6.5ZM12.75 4.5C7.75 4.5 3.48 7.61 1.75 12C3.48 16.39 7.75 19.5 12.75 19.5C17.75 19.5 22.02 16.39 23.75 12C22.02 7.61 17.75 4.5 12.75 4.5ZM12.75 9.5C14.13 9.5 15.25 10.62 15.25 12C15.25 13.38 14.13 14.5 12.75 14.5C11.37 14.5 10.25 13.38 10.25 12C10.25 10.62 11.37 9.5 12.75 9.5ZM12.75 7.5C10.27 7.5 8.25 9.52 8.25 12C8.25 14.48 10.27 16.5 12.75 16.5C15.23 16.5 17.25 14.48 17.25 12C17.25 9.52 15.23 7.5 12.75 7.5Z"
                            fill="#20419B"
                          />
                        </svg>
                      </span>
                      Xem
                    </button>
                  </div>
                  <div v-if="isMaster" :class="mdt_case.operation ? 'col-6' : 'col-12'">
                    <button class="btn bg-pri bd-pri text-white w-100" @click="showTTTQM(true)">
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.99902 17.2505V21.0005H6.74902L17.809 9.94055L14.059 6.19055L2.99902 17.2505Z"
                            fill="white"
                          />
                          <path
                            d="M20.709 5.63055L18.369 3.29055C17.979 2.90055 17.349 2.90055 16.959 3.29055L15.129 5.12055L18.879 8.87055L20.709 7.04055C21.099 6.65055 21.099 6.02055 20.709 5.63055Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      {{ mdt_case.operation ? "Chỉnh sửa" : "Soạn" }}
                    </button>
                  </div>
                  <div class="col-12" v-if="!isMaster && !mdt_case.operation">
                    <p class="fs-16 text-center txt-grey-600">Chưa có</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- !kết luận -->
        <div class="mb-5 row">
          <div class="col-md-6">
            <p class="fs-18 fw-600 txt-grey-900 mb-2">Thông tin bệnh nhân</p>
            <div class="d-flex">
              <p
                class="fs-16 mb-0 w-75 max-line-1 fw-500 txt-grey-900"
              >{{ mdtUtils.getMdtPersonInfoString(mdt_case) }}</p>
            </div>
          </div>
          <div class="col-md-6 pl-3 border-left">
            <p class="fs-18 fw-600 txt-grey-900 mb-2">Workspace</p>
            <p
              class="fs-16 mb-3 w-75 max-line-1 fw-500 txt-grey-900"
            >{{ mdt_case.clinic && mdt_case.clinic.name }}</p>
            <p class="fs-18 fw-600 txt-grey-900 mb-2">Bác sĩ</p>
            <p
              class="fs-16 mb-0 w-75 max-line-1 fw-500 txt-grey-900"
            >{{ mdt_disease.doctor && mdt_disease.doctor.name }}</p>
          </div>
        </div>

        <!-- Multilang -->
        <div class="flex items-center py-3">
          <svg
            class="mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.41 8.64C21.41 8.64 21.41 8.64 21.41 8.59C20.7054 6.66623 19.4269 5.00529 17.7475 3.83187C16.0681 2.65845 14.0688 2.02917 12.02 2.02917C9.97126 2.02917 7.97195 2.65845 6.29252 3.83187C4.6131 5.00529 3.33461 6.66623 2.63001 8.59C2.63001 8.59 2.63001 8.59 2.63001 8.64C1.84314 10.8109 1.84314 13.1891 2.63001 15.36C2.63001 15.36 2.63001 15.36 2.63001 15.41C3.33461 17.3338 4.6131 18.9947 6.29252 20.1681C7.97195 21.3416 9.97126 21.9708 12.02 21.9708C14.0688 21.9708 16.0681 21.3416 17.7475 20.1681C19.4269 18.9947 20.7054 17.3338 21.41 15.41C21.41 15.41 21.41 15.41 21.41 15.36C22.1969 13.1891 22.1969 10.8109 21.41 8.64ZM4.26001 14C3.91324 12.6892 3.91324 11.3108 4.26001 10H6.12001C5.96004 11.3285 5.96004 12.6715 6.12001 14H4.26001ZM5.08001 16H6.48001C6.71473 16.8918 7.05022 17.7541 7.48001 18.57C6.49931 17.9019 5.67951 17.0241 5.08001 16ZM6.48001 8H5.08001C5.67088 6.97909 6.4802 6.10147 7.45001 5.43C7.03057 6.24725 6.70515 7.10942 6.48001 8ZM11 19.7C9.77178 18.7987 8.90916 17.4852 8.57001 16H11V19.7ZM11 14H8.14001C7.9534 12.6732 7.9534 11.3268 8.14001 10H11V14ZM11 8H8.57001C8.90916 6.51477 9.77178 5.20132 11 4.3V8ZM18.92 8H17.52C17.2853 7.10816 16.9498 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0909 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0909 17.4852 14.2282 18.7987 13 19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9798 17.7541 17.3153 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0868 11.3108 20.0868 12.6892 19.74 14Z"
              fill="#41464B"
            />
          </svg>
          <span class="fs-16 fw-600 mr-3 text-black">
            {{
              $t("common.select_lang")
            }}
          </span>
          <el-dropdown class="mr-3">
            <span class="el-dropdown-link text-primary">
              <span class="fs-16 fw-600">{{ showLang }}</span>
              <i class="el-icon-arrow-down el-icon--right fs-16 fw-600"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(langCode, index) in Object.keys(LANGUAGE_CODES_FOR_DISEASE)"
                :key="index"
                :disabled="checkMultiLangData(LANGUAGE_CODES_FOR_DISEASE[langCode])"
              >
                <div
                  @click="setLang(LANGUAGE_CODES_FOR_DISEASE[langCode])"
                >{{ $t(`common.${langCode.toLowerCase()}`) }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-tooltip
            :content="
            $t(
              'multidisciplinary_board.lbl_tooltip_multi_lang_select_case_info'
            )
          "
            placement="top"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                fill="black"
              />
            </svg>
          </el-tooltip>
        </div>
        
        <div class="mb-3" v-if="mdt_case">
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Vào viện ngày:</p>
            <p
              class="pre-wrap fs-14 mb-0 txt-black"
            >{{mdt_case.admission_date && formatDate(mdt_case.admission_date)}}</p>
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Chẩn đoán:</p>
            <div class="pre-wrap fs-14 mb-0 txt-black" v-html="multilang.patient_diagnostic || patientDiagnostic(mdt_case)"></div>
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Chỉ định</p>
            <div v-html="multilang.indication || mdt_case.indication" />
          </div>
          <!-- <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">
              Tóm tắt bệnh án
            </p>
            <div v-html="mdt_case.summary_medical"/>
          </div>-->
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Giải phẫu bệnh</p>
            <div v-html="mdt_case.anatomic" />
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Phương pháp phẫu thuật</p>
            <div v-html="mdt_case.surgery_method" />
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Phương pháp vô cảm</p>
            <div v-html="mdt_case.anaesthetic_method" />
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Kíp phẫu thuật</p>
            <div v-html="mdt_case.surgical_team" />
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Kíp gây mê</p>
            <div v-html="mdt_case.anaesthetic_team" />
          </div>
          <div class="row mb-3">
            <p class="fs-16 fw-500 txt-black mb-1">Ngày mổ dự kiến</p>
            <div>{{mdt_case.surgery_date}}</div>
          </div>
        </div>
      </div>
    </div>

    <BienBanHCHT
      ref="BienBanHCHT"
      :currCase="mdt_case"
      :room_info="mdt_room"
      @reload="showPublicMdtCase"
      @refresh="refreshDiseaseConcluding"
      :checkin_members="checkin_members"
    />
    <TQMForm
      ref="TQMForm"
      :curr_disease="mdt_case"
      :room_info="mdt_room"
      @reload="showPublicMdtCase"
      @refresh="refreshTTTQm"
    />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import BienBanHCHT from '../../components/HoiChan/BienBanHCHT.vue'
import TQMForm from './TQMForm.vue'
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'
import _ from 'lodash'

export default {
  name: 'OrganizationMdtDiseaseDetail',
  components: { TQMForm, BienBanHCHT },
  data () {
    return {
      file_attach: [],
      mdt_disease: null,
      mdt_case: null,
      mdtUtils,
      mdt_room: null,
      room_name: '',
      checkin_members: [],
      user: {},
      usr_member: {},
      multilang: null,
      LANGUAGE_CODES_FOR_DISEASE
    }
  },
  computed: {
    fileAttachTest () {
      return this.file_attach.filter(f => f.type === 1 || f.type === 2)
    },
    fileAttachPacs () {
      return this.file_attach.filter(f => f.type === 3)
    },
    isMaster () {
      if (!this.usr_member) return false
      // return this.usr_member?.role === 4 || this.usr_member?.role === 1
      return true
    },
    showLang () {
      if (this.multilang) {
        const lang = appUtils.getLangCode(this.multilang.lang)
        this.lang = lang
        return this.$t(`common.${lang}`)
      }
    }
  },
  mounted () {
    this.showPublicMdtCase()
    this.user = appUtils.getLocalUser()
  },
  methods: {
    async showPublicMdtCase () {
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getPublicCaseDetail(this.$route.params.id)
      this.mdt_case = res.data
      this.mdt_disease = res.data.mdt_room_disease
      this.file_attach = res.data.mdt_room_disease.attachment
      const self = this
      const defaultData = {
        lang: appUtils.getLangId(this.$lang),
        patient_diagnostic: self.mdt_disease?.patient_diagnostic,
        patient_diseases_current_cure: self.mdt_disease?.patient_diseases_current_cure,
        conclusion: self.mdt_disease?.conclusion,
        treatment: self.mdt_disease?.treatment,
        indication: self.mdt_disease?.indication
      }
      if (self.mdt_disease?.multilang.length > 0) {
        let data = _.find(self.mdt_disease?.multilang, {
          lang: appUtils.getLangId(this.$lang)
        })

        if (!data) {
          data = self.mdt_disease?.multilang[0]
        }

        self.multilang = data
      } else {
        self.multilang = defaultData
      }
      this.showPublicMdtRoom(res.data.session.public_mdt_id)
      this.getPublicMdtCheckinMembers(res.data.public_mdt_id)
    },
    onClose () {
      if (this.mdt_case) {
        this.$router.push({
          name: 'ListMdt',
          query: { mdtid: this.mdt_case.public_mdt_id, type: 'ORG' }
        })
      }
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    openUrl (url) {
      var check = url.substring(0, 4)
      if (check === 'http') {
        window.open(url)
      } else {
        window.open('//' + url)
      }
    },
    formatHmDMY (dt) {
      return window.moment(dt).format('HH:mm - DD/MM/YYYY')
    },
    async showPublicMdtRoom (id) {
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getPublicMtdRoomDetails(id)
      this.mdt_room = res.data
      this.room_name = res.data?.room?.room_name
      this.usr_member = res?.data?.members?.find(
        m => m.user_id === this.user?.id
      )
    },
    async getPublicMdtCheckinMembers (id) {
      let self = this
      let params = {
        date: window.moment().format('YYYY-MM-DD'),
        public_mdt_id: id,
        limit: 1000
      }
      self.$rf
        .getRequest('AuthRequest')
        .getPublicMdtCheckinMembers(params)
        .then(r => {
          self.checkin_members = r.data
        })
    },
    checkMultiLangData (langVal) {
      if (langVal && this.mdt_disease.multilang) {
        const data = _.find(this.mdt_disease.multilang, { lang: langVal })
        if (!data) {
          return true
        } else {
          return false
        }
      } else if (
        langVal &&
        this.multilang !== null &&
        typeof this.multilang !== 'undefined'
      ) {
        if (this.multilang?.lang !== langVal) {
          return true
        } else {
          return false
        }
      }
    },
    setLang (value) {
      if (value) {
        const data = _.find(this.mdt_disease.multilang, { lang: value })
        this.multilang = data
      }
    },
    refreshTTTQm () {
      this.showPublicMdtCase()
      this.showTTTQM(false)
    },
    showTTTQM (show) {
      this.$refs.TQMForm.openModal(show)
      // window.$("#modalTTTQM").modal(show ? "show" : "hide");
    },
    viewTTTQM () {
      if (this.mdt_case?.operation) {
        window.open(this.mdt_case.operation.pdf)
      }
    },
    refreshDiseaseConcluding () {
      this.showPublicMdtCase()
      this.showDiseaseConcluding(false)
    },
    showDiseaseConcluding (show) {
      this.$refs.BienBanHCHT.openModal(show)
      // window.$("#modalAddBienBan").modal(show ? "show" : "hide");
    },
    backToMdtDetail () {
      this.$router.push({
        name: 'ListMdt',
        query: { mdtid: this.mdt_case.public_mdt_id }
      })
    },
    backToMdtSession () {
      this.$router.push({
        name: 'OrganizationSessionDetail',
        params: { id: this.mdt_case.public_mdt_session_id }
      })
    },
    onUpdate () {
      this.$router.push({
        name: 'OrganizationUpdateMdtDisease',
        params: { id: this.mdt_case.id }
      })
    },
    openOriginCase () {
      if (this.mdt_disease) {
        let url = this.$router.resolve({
          name: 'MdtDiseaseDetail',
          params: { id: this.mdt_disease.id }
        })
        window.open(url.href)
      }
    },
    patientDiagnostic (data) {
      const mdt_room_disease = data?.mdt_room_disease
      if (data.patient_diagnostic) {
        return data.patient_diagnostic
      }

      if (mdt_room_disease?.multilang?.length) {
        const langId = this.langId
        const r = mdt_room_disease?.multilang.find(
          item => item.lang === langId
        )
        if (!r) return mdt_room_disease?.patient_diagnostic
        else return r.patient_diagnostic
      }
      return mdt_room_disease?.patient_diagnostic
    }
  }
}
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}
.test-wraper {
  width: 100%;
  max-width: 491px;
  border-radius: 10px;
  padding: 12px 12px 16px 12px;
  background-color: #f9f8f8;
}
.test-item {
  height: 254px;
  max-width: 467px;
  width: 100%;
  left: 12px;
  top: 12px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 16px;
}
.test-item.has-item {
  background-color: #fff;
}
.test-item.hasnt-item {
  background: #d9d9d9;
}
.btn-add {
  height: 57px;
  width: 75%;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.btn-add-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 109px;
  border-radius: 10px;
  padding: 8px 16px;
  border: 1px dashed #20419b;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.concluding-wraper {
  height: 192px;
  background: #f9f8f8;
  border-radius: 10px;
}
.concluding-file-open {
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      rgba(54, 54, 54, 0.8),
      rgba(54, 54, 54, 0.8)
    ),
    url("../../../public/assets/images/MDT/bienban-bg.png");
  background-color: #363636;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-add-disease {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 10px;
  padding: 8px;
  border: 1px dashed #20419b;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background: #fff;
}
.btn {
  height: 37px;
}
.bg--E7EAF5 {
  background-color: #e7eaf5;
}
.bg-inherit {
  background-color: inherit;
}
</style>
