<template>
  <div>
    <div
      class="modal fade"
      id="modalAddBienBan"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddBienBanLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document" v-if="currCase">
        <div class="modal-content p-3">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5
              class="modal-title"
              id="modalAddBienBanLabel"
            >{{ $t('multidisciplinary_board.lbl_mdt_report') }}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-md-6">
                <p class="fs-14 txt-grey-900 mb-2">
                  {{ $t('multidisciplinary_board.lbl_patients_name') }}:
                  <span
                    class="fw-700"
                  >{{ currCase && currCase.patient_name }}</span>
                </p>
                <div class="d-flex">
                  <p class="fs-14 txt-grey-900 mb-2">
                    {{ $t('multidisciplinary_board.lbl_diagnose') }}:
                    <span>{{ currCase && currCase.patient_diagnostic }}</span>
                  </p>
                </div>
                <p class="fs-14 txt-grey-900 mb-2">
                  {{ $t('multidisciplinary_board.lbl_mems_type_chairman') }}:
                  <span>{{ preside && preside.user && preside.user.name }}</span>
                </p>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-between">
                  <p class="fs-14 txt-grey-900 mb-2">
                    {{ $t('health_book_page.lbl_age') }}:
                    <span class="fw-700">
                      {{
                      currCase &&
                      currCase.patient_birthday &&
                      getAges(currCase.patient_birthday)
                      }}
                    </span>
                  </p>
                  <p class="fs-14 txt-grey-900 mb-2">
                    {{ $t('multidisciplinary_board.lbl_genders') }}:
                    <span class="fw-700">
                      {{
                      currCase &&
                      currCase.patient_gender &&
                      getGender(currCase.patient_gender)
                      }}
                    </span>
                  </p>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <p
                    class="fs-14 txt-grey-900 mb-0 mr-2 w-50"
                  >{{ $t('multidisciplinary_board.lbl_mdt_at') }}:</p>
                  <input
                    type="datetime-local"
                    class="border fs-14 form-control bg-white px-2 py-1"
                    v-model="form.mdt_time"
                    :readonly="!isMaster"
                  />
                </div>
                <p class="fs-14 txt-grey-900 mb-2">
                  {{ $t('multidisciplinary_board.lbl_mems_type_secretary') }}:
                  <span>
                    {{
                    currCase && currCase.mdt_room_disease && currCase.mdt_room_disease.doctor &&
                    currCase.mdt_room_disease.doctor.name
                    }}
                  </span>
                </p>
              </div>
              <div class="col-12 d-flex mb-2">
                <div class="w-150px">
                  <p
                    class="fs-14 txt-grey-900 text-nowrap mb-0 mr-2"
                  >{{ $t('multidisciplinary_board.lbl_participant') }}:</p>
                </div>
                <div class="w--150px">
                  <textarea
                    class="form-control bg-white border"
                    v-model="form.members"
                    rows="2"
                    :readonly="!isMaster"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center">
                <div class="w-150px">
                  <p class="fs-14 txt-grey-700 mb-0 mr-2 text-nowrap">
                    {{ $t('multidisciplinary_board.lbl_profile_code')
                    }}:
                  </p>
                </div>
                <div class="w--150px">
                  <input
                    type="text"
                    class="border bg-white h-100 fs-18 w-100 form-control"
                    v-model="form.profile_code"
                    :readonly="!isMaster"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <p class="fs-14 txt-grey-900">{{ $t('multidisciplinary_board.lbl_treat_duration') }}</p>
            <input
              type="text"
              v-model="form.treatment_time"
              class="border form-control bg-white"
              :readonly="!isMaster"
            />
          </div>
          <div class="mb-3">
            <p class="fs-14 txt-grey-900">{{ $t('multidisciplinary_board.lbl_treatment_his') }}</p>
            <EditorInput
              id="treatment_progress-bbhcht"
              :disabled="!isMaster"
              :prop_text="form.treatment_progress"
              @onChangeContent="(content) => form.treatment_progress = content"
            />
          </div>
          <div class="mb-3">
            <p class="fs-14 txt-grey-900">{{ $t('multidisciplinary_board.lbl_conclusion') }}</p>
            <EditorInput
              id="conclusion-bbhcht"
              :disabled="!isMaster"
              :prop_text="form.conclusion"
              @onChangeContent="(content) => form.conclusion = content"
            />
          </div>
          <div class="mb-3">
            <p class="fs-14 txt-grey-900">{{ $t('multidisciplinary_board.lbl_treatment_plan') }}</p>
            <EditorInput
              id="treatment-bbhcht"
              :disabled="!isMaster"
              :prop_text="form.treatment"
              @onChangeContent="(content) => form.treatment = content"
            />
          </div>
          <div class="mb-3 d-flex justify-content-end">
            <button
              :disabled="!isMaster || loading"
              class="btn-action btn bg-pri bd-pri text-white"
              @click="submit()"
            >{{ $t('health_book_page.lbl_save') }}</button>
            <!-- <button :disabled="!isMaster || loading" class="btn-action btn bg-pri bd-pri text-white"
              @click="submit(true)">
              {{ $t('multidisciplinary_board.lbl_complete') }}
            </button>-->
            <button
              class="btn-action btn bg-pri bd-pri text-white"
              disabled
            >{{ $t('multidisciplinary_board.lbl_sign') }}</button>
            <button
              class="btn-action btn bg-pri bd-pri text-white"
              disabled
            >{{ $t('multidisciplinary_board.lbl_print') }}</button>
          </div>
        </div>
      </div>
    </div>
    <TempBienBanHCHT
      :form="form"
      :save_pdf="save_pdf_count"
      @exportError="loading = false"
      @onSubmiting="onSubmiting"
      :mdt_case="currCase"
      :room_info="room_info"
    />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import TempBienBanHCHT from '../../templates/BienBan/TempBienBanHCHT.vue'
import EditorInput from '@/components/EditorInput.vue'
import {
  LANGUAGE_CODES_FOR_DISEASE,
  LANGUAGE_CODES_NAME_FOR_DISEASE,
  LANGUAGE_CODES_NUMBER_FOR_DISEASE
} from '@/lang'

export default {
  name: 'BienBanHCHT',
  props: ['currCase', 'room_info', 'checkin_members'],
  components: { TempBienBanHCHT, EditorInput },
  data () {
    return {
      form: {
        treatment_time: '',
        treatment_progress: '',
        conclusion: '',
        treatment: '',
        mdt_time: '',
        members: '',
        profile_code: ''
      },
      mdtUtils,
      save_pdf_count: 0,
      loading: false,
      update_status: false,
      LANGUAGE_CODES_FOR_DISEASE,
      LANGUAGE_CODES_NAME_FOR_DISEASE,
      LANGUAGE_CODES_NUMBER_FOR_DISEASE
    }
  },
  watch: {
    currCase () {
      this.handlerDisease()
    },
    checkin_members (members) {
      this.handlerMemberstring()
    }
  },
  computed: {
    isMaster () {
      if (!this.usr_member) return false
      // return this.usr_member?.role === 4 || this.usr_member?.role === 1
      return true
    },
    preside () {
      return this.room_info?.members?.find(m => m.role === 1)
    },
    secretaries () {
      return this.room_info?.members?.filter(m => m.role === 1)
    },
    usr_member () {
      return this.room_info?.members?.find(
        m => m.user_id === appUtils.getLocalUser()?.id
      )
    },
    langId () {
      return appUtils.getLangId(this.$lang || 'vi')
    }
  },
  mounted () {
    this.user_id = appUtils.getLocalUser()?.id
    this.handlerDisease()
    this.handlerMemberstring()
  },
  methods: {
    handlerDisease () {
      const d = this.currCase
      if (d) {
        if (d.concluding) {
          this.form.treatment_time = d.concluding.treatment_time || null
          this.form.treatment_progress =
            d.concluding.treatment_progress
          this.form.conclusion = d.concluding.conclusion
          this.form.treatment = d.concluding.treatment
          if (d.concluding.members) this.form.members = d.concluding.members
          if (d.concluding.profile_code) {
            this.form.profile_code = d.concluding.profile_code
          }
          this.form.mdt_time = d.concluding.mdt_time
            ? window
              .moment(d.concluding.mdt_time, 'YYYY-MM-DD HH:mm:ss')
              .format('YYYY-MM-DDTHH:mm')
            : window.moment('HH:mm:ss').format('YYYY-MM-DDTHH:mm')
        } else {
          if (d.mdt_room_disease?.concluding) {
            const mdtD = d.mdt_room_disease
            const mdtDConcluding = mdtD?.concluding
            this.form.treatment_time =
              mdtDConcluding.treatment_time ||
              `${appUtils.formatDateTime(
                mdtDConcluding.treatment_time_start
              )} - ${appUtils.formatDateTime(
                mdtDConcluding.treatment_time_end
              )}` ||
              null
            this.form.treatment_progress = mdtDConcluding.treatment_progress ||
              mdtD.multilang.find(el => el.lang === this.langId).patient_diseases_current_cure ||
              mdtD.patient_diseases_current_cure || ''
            this.form.conclusion = mdtDConcluding.conclusion ||
              mdtD.multilang.find(el => el.lang === this.langId).conclusion ||
              mdtD.conclusion || ''
            this.form.treatment = mdtDConcluding.treatment ||
                mdtD.multilang.find(el => el.lang === this.langId).treatment ||
                mdtD.treatment || ''
            if (mdtDConcluding.profile_code) {
              this.form.profile_code = mdtDConcluding.profile_code
            }
            this.form.mdt_time = mdtDConcluding.mdt_time
              ? window
                .moment(mdtDConcluding.mdt_time, 'YYYY-MM-DD HH:mm:ss')
                .format('YYYY-MM-DDTHH:mm')
              : window.moment('HH:mm:ss').format('YYYY-MM-DDTHH:mm')
          } else {
            this.form.treatment_time = ''
            this.form.treatment_progress = d.patient_diseases_current_cure
            this.form.conclusion = d.conclusion
            this.form.treatment = d.treatment
            this.form.profile_code = d.code
            this.form.mdt_time = window.moment().format('YYYY-MM-DDTHH:mm')
          }
        }
      } else {
        this.form = {
          treatment_time: '',
          treatment_progress: '',
          conclusion: '',
          treatment: '',
          mdt_time: window.moment().format('YYYY-MM-DDTHH:mm'),
          members: '',
          profile_code: ''
        }
      }
    },
    handlerMemberstring () {
      let members = this.checkin_members
      if (members && members.count && !this.form.members) {
        let string = ''
        members.data.forEach(m => {
          if (m.status === 1) {
            string += m?.user?.name + ', '
          }
        })
        this.form.members = string
      }
    },
    submit (update) {
      if (!this.form.conclusion || !this.form.treatment) {
        alert(
          'Cần có đủ Kết luận và Hướng điều trị mới lưu được Biên bản, vui lòng cập nhật'
        )
        this.handlerDisease()
        return
      }
      this.loading = true
      this.update_status = update
      this.save_pdf_count++
    },
    formatHmDMY (date) {
      let string = window.moment(date).format('HH:mm DD/MM/YYYY')
      string.split(' ').join(' ngày ')
      return string
    },
    onSubmiting (file) {
      console.log(file)
      if (!this.currCase) return
      if (this.currCase.concluding) {
        this.mdtCaseConcludingUpdate(file)
      } else {
        this.mdtCaseConcludingCreate(file)
      }
    },
    async mdtCaseConcludingCreate (file = null) {
      let self = this
      if (!this.isMaster) return
      let params = {
        name: self.currCase.patient_name,
        age: self.currCase.patient_birthday
          ? self.getAges(self.currCase.patient_birthday)
          : null,
        sex: self.currCase.patient_gender,
        address: self.currCase.patient_address,
        public_mdt_case_id: self.currCase.id,
        treatment_time: self.form.treatment_time,
        treatment_progress: self.form.treatment_progress,
        profile_code: self.form.profile_code,
        conclusion: self.form.conclusion,
        treatment: self.form.treatment,
        mdt_time: self.form.mdt_time
          ? window.moment(self.form.mdt_time).format('HH:mm DD-MM-YYYY')
          : window.moment().format('HH:mm DD-MM-YYYY'),
        public_mdt_id: this.room_info?.id,
        members: self.form.members
      }
      if (file) params.file = file
      let formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .mdtCaseConcludingCreate(formData)
        .finally(() => {
          self.onFinishSubmit()
        })
    },
    async mdtCaseConcludingUpdate (file = null) {
      let self = this
      if (!this.isMaster || !self.currCase.concluding) return
      let params = {
        name: self.currCase.patient_name,
        age: self.currCase.patient_birthday
          ? self.getAges(self.currCase.patient_birthday)
          : null,
        sex: self.currCase.patient_gender,
        address: self.currCase.patient_address,
        public_mdt_case_id: self.currCase.id,
        treatment_time: self.form.treatment_time,
        treatment_progress: self.form.treatment_progress,
        profile_code: self.form.profile_code,
        conclusion: self.form.conclusion,
        treatment: self.form.treatment,
        mdt_time: window.moment(self.form.mdt_time).format('HH:mm DD-MM-YYYY'),
        public_mdt_id: this.room_info?.id,
        members: self.form.members
      }
      if (file) params.file = file
      let formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      console.log(formData, params)
      await self.$rf
        .getRequest('DoctorRequest')
        .mdtCaseConcludingUpdate(self.currCase.concluding.id, formData)
        .then(r => {
          console.log(r)
        })
        .finally(() => {
          self.onFinishSubmit()
        })
    },
    onFinishSubmit () {
      if (
        this.update_status &&
        this.currCase !== 1 &&
        this.currCase !== 2 &&
        this.currCase !== 3
      ) {
        this.onPublicMdtCaseStatus(1)
      }
      this.$emit('refresh')
      this.loading = false
    },
    async onPublicMdtCaseStatus (stt) {
      if (!this.currCase) return
      const self = this
      const params = {
        status: stt
      }
      await self.$rf
        .getRequest('AuthRequest')
        .publicMdtCaseStatus(this.currCase.id, params)
        .then(() => {
          // self.$toast.open({
          //   message: 'Bắt đầu thành công',
          //   type: 'success'
          // })
        })
        .then(() => {})
        .finally(() => {})
    },
    getAges (date) {
      let bd = window.moment(date, 'YYYY-MM-DD')
      let age = window.moment().diff(bd, 'years')
      return age
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    openModal (show) {
      if (show) {
        this.$emit('reload')
      }

      window.$('#modalAddBienBan').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
.form-control {
  height: unset;
}

.btn-action {
  width: 150px;
  margin-left: 24px;
}

.fs-18 {
  font-size: 18px;
}

.w-150px {
  width: 150px;
}

.w--150px {
  width: calc(100% - 150px);
}

.form-control {
  border-radius: 0 !important;
}
</style>
